import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../layouts"
import '../styles/plan-your-visit.scss';
import { Helmet } from "react-helmet"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../utils/customMarkdown";
import 'uikit/dist/css/uikit.css'

import brochure from "../assets/brochure.pdf";



// const CTAList = loadable(() => import("../components/cta-list/ctaList"))

import CTAList from '../components/cta-list/ctaList';

const VisitUs = (props) => {

    const data = useStaticQuery(graphql`
        {  
          allFile {
            edges {
              node {
                publicURL
                id
                name
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          strapiPlanYourVisit {
            SEODescription
            SEOTitle
            Title
            Description
            Paragraph
            ImageLink {
                Link
                Label
                Image {
                    localFile {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 1900) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                            gatsbyImageData(
                            width: 1900
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
          }
            
        }


    `)

    const contentsList = [];

    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        // console.log(imgUrl)
        return (imgUrl)
    }

    var searchURL = "";

    if (typeof window !== "undefined" && props.location?.search != undefined && props.location.search.split('=').length >= 1) {
        searchURL = props.location.search.split('=')[1];
    }



    return (

        <Layout SEOTitle={data.strapiPlanYourVisit.SEOTitle} SEODescription={data.strapiPlanYourVisit.SEODescription}>

            {/** List of meta properties coming from strapi that applies only for the homepage */
                <Helmet>
                    {data.strapiPlanYourVisit.MetaTags?.map((item, index) => {
                        return (
                            <>
                                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
                            </>
                        )
                    })}
                </Helmet>
            }

            <div className="visit-container">
                <div className="visit-content">

                    <div className="visit-page-header">
                        {
                            data.strapiPlanYourVisit.Title ? <h3>{data.strapiPlanYourVisit.Title}</h3> : ""
                        }
                        {
                            data.strapiPlanYourVisit.Description ? <p>{data.strapiPlanYourVisit.Description}</p> : ""

                        }
                    </div>

                    <div className="visit-page-text">
                        {
                            data.strapiPlanYourVisit.Paragraph ? <p><CustomMarkdown children={data.strapiPlanYourVisit.Paragraph}></CustomMarkdown></p> : ""
                        }
                    </div>

                    <div className="visit-images">
                        <div className="paddings-img uk-child-width-1-2@s uk-grid" uk-grid="true">
                            {
                                data.strapiPlanYourVisit.ImageLink ? data.strapiPlanYourVisit.ImageLink.map((item, index) => {

                                    return (

                                        <li key={index}>
                                            <div>
                                                {
                                                    item.Link === "pdf" ?
                                                        <a href={brochure} target="_blank" rel="noreferrer" id="email">
                                                            <GatsbyImage image={item.Image.localFile.childImageSharp.gatsbyImageData} alt="directions" className="directions-image"></GatsbyImage>
                                                        </a>
                                                        :
                                                        <Link to={item.Link}>
                                                            <GatsbyImage image={item.Image.localFile.childImageSharp.gatsbyImageData} alt="directions" className="directions-image"></GatsbyImage>
                                                        </Link>
                                                }
                                                <span>{item.Label}</span>
                                            </div>
                                        </li>



                                    )
                                })
                                    : ""
                            }
                        </div>
                    </div>


                </div>
            </div>


        </Layout>

    )

}

export default VisitUs